import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loadingQuestionGroup: true,
            questionGroupList: []
        }
    },
    computed: {
        ...mapGetters(['getQuestionGroupList', 'getQuestionGroupListIsLoading'])
    },
    methods: {
        async GET_QUESTION_GROUP_LIST() {
            console.log(this.$route.params, this.getQuestionGroupList);
            if (this.getQuestionGroupList.id === this.$route.params.reportId && this.getQuestionGroupList.list.length !== 0) {
                this.questionGroupList = this.getQuestionGroupList.list;
                this.$store.commit('SET_QUESTION_GROUP_LIST_IS_LOADING', false);
                return
            }
            this.$store.commit('SET_QUESTION_GROUP_LIST_IS_LOADING', true);
            const response = await this.$api.get(`QuestionGroups/ByReport?reportId=${this.$route.params.reportId}`);
            if (response.result === "OK" && response.message === "OK") {
                const list = response.data.map(d => {
                    return {
                        ...d,
                        questions: []
                    }
                });
                this.questionGroupList = list;
                this.$store.commit('SET_QUESTION_GROUP_LIST', { id: this.$route.params.reportId, list })
                if (list.length !== 0)
                    this.$store.dispatch('SEND_QUESTION_BY_GROUP', { id: list[0].groupId })

            }
            else {
                this.$store.commit('SET_QUESTION_GROUP_LIST', { id: this.$route.params.reportId, list: [] })
                this.questionGroupList = [];
            }
            this.$store.commit('SET_QUESTION_GROUP_LIST_IS_LOADING', false);
        }
    },
    mounted() {
        this.GET_QUESTION_GROUP_LIST();
    }
}