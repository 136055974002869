<template>
  <div class="my-5">
    <h6 class="my-4">
      If the product you want to create a report for is not listed below, Please
      <strong class="text-primary">+ Create it</strong>.
    </h6>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-5 col-xl-5 my-2">
        <div class="my-4" v-if="!edit">
          <router-link
            :to="`/${edit ? 'update' : 'create'}-report/${
              $route.params.reportId
            }`"
          >
            <button class="btn my-2 d-flex btn-info">
              <i class="mr-2 material-icons-outlined">arrow_back</i> Back to
              Step 1
            </button>
          </router-link>
        </div>
        <div class="form-group">
          <label for="taxno">Company Name</label>
          <span class="badge badge-danger ml-2">required</span>
          <input
            class="form-control"
            id="taxno"
            maxlength="50"
            v-model="activeCompany.name"
            disabled
            required
            aria-describedby="emailHelp"
            placeholder="Enter Company Name"
          />
          <div v-if="!edit" class="mt-2 text-right">
            <router-link
              :to="`/create-report/${$route.params.reportId}`"
              class="text-primary"
            >
              Change to Company
            </router-link>
          </div>
        </div>
        <!-- LIST BRAND  -->
        <div class="mt-4">
          <label for="">Brand Name</label>
          <span class="badge badge-danger ml-2">required</span>
          <v-select
          :key="7"
            @search="GET_BRAND_LIST"
            label="name"
            class="style-chooser"
            :value="!activeBrand.status ? null : activeBrand"
            ref="brandSelect"
            @option:selected="SET_SELECTED_BRAND"
            :clearable="false"
            placeholder="Search brand name"
            :options="brands"
          >
            <template #no-options="{ search }">
              Sorry, No matching "{{ search }}"
              <div class="mt-4">
                <a
                  @click="
                    newBrand.name = search;
                    newBrandModal = true;
                  "
                  href="javascript:void(0)"
                  class="text-primary"
                >
                  + Create a New Brand
                </a>
              </div>
            </template></v-select
          >
          <div class="mt-2 text-right">
            <a
              @click="newBrandModal = true"
              href="javascript:void(0)"
              class="text-primary"
            >
              + Create a New Brand
            </a>
          </div>
        </div>
        <!-- LIST PRODUCT  -->
        <div class="mt-4">
          <label for="">Model Number</label>
          <span class="badge badge-danger ml-2">required</span>
          <v-select
          :key="8"
            @search="GET_PRODUCT_LIST"
            :disabled="!activeBrand.status"
            ref="productSelect"
            label="model"
            class="style-chooser"
            :value="!activeProduct.status ? null : activeProduct"
            @option:selected="SET_SELECTED_PRODUCT"
            :clearable="false"
            placeholder="Search Model Number"
            :options="products"
          >
            <template #no-options="{ search }">
              Sorry, No matching "{{ search }}"
              <div class="mt-4">
                <a
                  @click="
                    newProduct.model = search;
                    !activeBrand.status ? '' : (newProductModal = true);
                  "
                  href="javascript:void(0)"
                  :class="`text-${!activeBrand.status ? 'white' : 'primary'}`"
                >
                  + Create a New Model for The {{ activeBrand.name }}
                </a>
              </div>
            </template></v-select
          >
          <div class="mt-2 text-right">
            <a
              @click="!activeBrand.status ? '' : (newProductModal = true)"
              href="javascript:void(0)"
              :class="`text-${!activeBrand.status ? 'white' : 'primary'}`"
            >
              + Create a New Model for The {{ activeBrand.name }}
            </a>
          </div>
        </div>
        <!-- LIST COMPANY PRODUCT (SERIAL NUMBER) -->
        <div class="mt-4" v-if="activeProduct.status">
          <label for="">Serial Number / Machine Location</label>
          <span class="badge badge-danger ml-2">required</span>
          <v-select
          :key="9"
            @search="GET_SERIAL_NUMBER_LIST"
            ref="serialNumbers"
            label="serialNo"
            :filterable="false"
            class="style-chooser"
            :value="!activeSerialNumber.status ? null : activeSerialNumber"
            @option:selected="SET_SELECTED_SERIAL_NUMBER"
            :clearable="false"
            placeholder="Search serial number"
            :options="serialNumbers"
          >
            <template #option="{ productLocation, serialNo }">
              <div>
                <strong class="mr-2">S.No:</strong> <i> {{ serialNo }}</i
                >, <strong class="mr-2">ML:</strong>
                <i>{{ productLocation }}</i>
              </div>
            </template>
            <template #no-options="{ search }">
              Sorry, No matching "{{ search }}"
              <div class="mt-4">
                <a
                  @click="
                    newSerialNumber.serialNo = search;
                    !activeProduct.status ? '' : (newSerialNumberModal = true);
                  "
                  href="javascript:void(0)"
                  :class="`text-${!activeProduct.status ? 'white' : 'primary'}`"
                >
                  + Create a New Serial Number for The {{ activeProduct.model }}
                </a>
              </div>
            </template>
          </v-select>
          <div class="mt-2 text-right">
            <a
              @click="
                !activeProduct.status ? '' : (newSerialNumberModal = true)
              "
              href="javascript:void(0)"
              :class="`text-${!activeProduct.status ? 'white' : 'primary'}`"
            >
              + Create a New Serial Number for The {{ activeProduct.model }}
            </a>
          </div>
        </div>
        <div
          class="mt-4 text-right d-flex justify-content-end align-items-center"
          v-if="activeSerialNumber.status"
        >
          <button @click="SET_NEXT_STEP" class="btn d-flex btn-primary">
            Continue to Step 3
            <i class="ml-2 material-icons-outlined">arrow_forward</i>
          </button>
        </div>
      </div>
    </div>
    <!-- CREATE NEW BRAND -->
    <modal
      title="Create a New Brand"
      okText="Save"
      @ok="SEND_NEW_BRAND"
      v-model="newBrandModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label for="taxno">Brand Name</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="newBrand.name"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Brand Name"
        />
      </div>
    </modal>
    <!-- CREATE NEW PRODUCT -->
    <modal
      title="Create a New Product"
      okText="Save"
      @ok="SEND_NEW_PRODUCT"
      v-model="newProductModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label for="taxno">Brand Name</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="activeBrand.name"
          required
          disabled
          aria-describedby="emailHelp"
          placeholder="Enter Brand Name"
        />
      </div>
      <div class="form-group">
        <label for="taxno">Model Number</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="newProduct.model"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Model Number"
        />
      </div>
    </modal>
    <!-- CREATE NEW SERIAL NUMBER -->
    <modal
      title="Create a New Serial Number"
      okText="Save"
      @ok="SEND_NEW_SERIAL_NUMBER"
      v-model="newSerialNumberModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label for="taxno">Brand Name</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="activeBrand.name"
          required
          disabled
          aria-describedby="emailHelp"
          placeholder="Enter Brand Name"
        />
      </div>
      <div class="form-group">
        <label for="taxno">Model Number</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="activeProduct.model"
          required
          disabled
          aria-describedby="emailHelp"
          placeholder="Enter Model Number"
        />
      </div>
      <div
        class="alert"
        :class="`alert-info`"
      >
        <small>Serial Number is required</small>
      </div>
      <div class="form-group">
        <label for="taxno">Serial Number</label>
        <span class="badge badge-danger ml-2">required</span>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="newSerialNumber.serialNo"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Serial Number"
        />
      </div>
      <div class="form-group">
        <label for="taxno">Machine Location</label>
        <input
          class="form-control"
          id="taxno"
          maxlength="50"
          v-model="newSerialNumber.productLocation"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Machine Location"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "../../../ui/modal.vue";
import BrandActions from "./brandActions";
import ProductActions from "./productActions";
import SerialNumbersActions from "./serialNumberActions";
import getQuestionGroups from "./questions/getQuestionGroups";

export default {
  mixins: [BrandActions, ProductActions, SerialNumbersActions, getQuestionGroups],
  components: {
    Modal,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      alert: { status: false, message: "", variant: "success" },
      activeCompany: { status: false },
    };
  },
  methods: {
    SET_NEXT_STEP() {
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "serialNo",
        data: this.activeSerialNumber,
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "product",
        data: this.activeProduct,
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "company",
        data: this.activeCompany,
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "brand",
        data: this.activeBrand,
      });
      this.$router.push(
        `/${this.edit ? "update" : "create"}-report/${
          this.$route.params.reportId
        }/questions`
      );
    },
  },
  mounted() {
    this.GET_BRAND_LIST("", this.$refs.brandSelect.toggleLoading);
    this.activeCompany = this.$store.state.createReport.createReport.company;
    this.activeSerialNumber =
      this.$store.state.createReport.createReport.serialNo;
    this.activeProduct = this.$store.state.createReport.createReport.product;
    this.activeBrand = this.$store.state.createReport.createReport.brand;
    setTimeout(() => {
      if (this.activeProduct.status)
        this.GET_PRODUCT_LIST("", this.$refs.productSelect.toggleLoading);
      if (this.activeSerialNumber.status)
        this.GET_SERIAL_NUMBER_LIST("", this.$refs.serialNumbers.toggleLoading);
    }, 250);
    if (!this.activeCompany.status)
      this.$router.push(`/create-report/${this.$route.params.reportId}`);
  },
};
</script>

<style>
</style>