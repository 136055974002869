export default {
    data() {
        return {
            setmout: -1,
            newBrandModal: false,
            newBrand: { name: "", isActive: 1 },
            brands: [],
            activeBrand: {
                status: false,
                id: 0
            },
        }
    },
    methods: {
        GET_BRAND_LIST(search = "", loading) {
            loading(true);
            if (this.setmout != -1) clearTimeout(this.setmout);
            this.setmout = setTimeout(async () => {
                const response = await this.$api.get(`Brands?searchKey=${search}&limit=10&offset=1`);
                if (response.result === "OK" && response.message === "OK") {
                    this.brands = response.data.items;
                } else this.brands = [];
                loading(false);
            }, 500);
        },
        SET_SELECTED_BRAND(row) {
            this.activeProduct = { status: false };
            this.activeSerialNumber = { status: false };
            this.activeBrand = { ...row, status: true };
            this.GET_PRODUCT_LIST("", this.$refs.productSelect.toggleLoading);
        },
        async SEND_NEW_BRAND(e, loading) {
            if (this.newBrand.name === "") return;
            loading();
            const response = await this.$api.post("Brands", {
                name: this.newBrand.name,
                isActive: this.newBrand.isActive,
            });
            if (response.result === "OK" && response.message === "OK") {
                this.alert = {
                    status: true,
                    variant: "success",
                    message: "Brand saved successfully",
                };
                this.brands.unshift({
                    id: response.data,
                    ...this.newBrand,
                });
                this.activeBrand = {
                    id: response.data,
                    ...this.newBrand, status: true
                };

                setTimeout(() => {
                    this.newBrandModal = false;
                    this.alert.status = false;
                }, 1500);
            } else {
                this.alert = {
                    status: true,
                    variant: "danger",
                    message: "Something went wrong. Please Try Again",
                };
            }
            loading(false);
        },
    }
}