export default {
    data() {
        return {
            setmoutSerialNumber: -1,
            newSerialNumberModal: false,
            newSerialNumber: { name: "", isActive: 1, productLocation: "", serialNo: "" },
            serialNumbers: [],
            activeSerialNumber: {
                status: false,
                id: 0
            },
        }
    },
    methods: {
        GET_SERIAL_NUMBER_LIST(search = "", loading) {
            loading(true);
            if (this.setmoutSerialNumber != -1) clearTimeout(this.setmoutSerialNumber);
            this.setmoutSerialNumber = setTimeout(async () => {
                const response = await this.$api.get(`CompanyProducts?searchKey=${search}&companyId=${this.activeCompany.id === -2 || this.activeCompany.id === -1 ? 0 : this.activeCompany.id}&productId=${this.activeProduct.id}`);
                if (response.result === "OK" && response.message === "OK") {
                    this.serialNumbers = response.data.items.map(it => {
                        return {
                            ...it,
                            id: it.companyProduct.id,
                            serialNo: it.companyProduct.serialNo,
                            productLocation: it.companyProduct.productLocation
                        }
                    });
                } else this.serialNumbers = [];
                loading(false);
            }, 500);
        },
        SET_SELECTED_SERIAL_NUMBER(row) {
            console.log(row);
            this.activeSerialNumber = { ...row, status: true, serialNo: row.companyProduct.serialNo, productLocation: row.companyProduct.productLocation };
            // this.GET_PRODUCT_LIST("", this.$refs.productSelect.toggleLoading);
        },
        async SEND_NEW_SERIAL_NUMBER(e, loading) {
            if (this.newSerialNumber.serialNo === "") return;
            if (this.newSerialNumber.productLocation === '') this.newSerialNumber.productLocation = '-'
            loading();
            const response = await this.$api.post("CompanyProducts", {
                companyId: this.activeCompany.id,
                productId: this.activeProduct.id,
                serialNo: this.newSerialNumber.serialNo,
                productLocation: this.newSerialNumber.productLocation,
            });
            if (response.result === "OK" && response.message === "OK") {
                this.alert = {
                    status: true,
                    variant: "success",
                    message: "Serial Number saved successfully",
                };
                this.serialNumbers.unshift({
                    id: response.data,
                    ...this.newSerialNumber,
                });
                this.activeSerialNumber = {
                    id: response.data,
                    ...this.newSerialNumber, status: true
                };
                setTimeout(() => {
                    this.newSerialNumberModal = false;
                    this.alert.status = false;
                }, 1500);
            } else {
                this.alert = {
                    status: true,
                    variant: "danger",
                    message: "Something went wrong. Please Try Again",
                };
            }
            loading(false);
        },
    }
}