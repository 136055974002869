export default {
    data() {
        return {
            setmoutProducts: -1,
            newProductModal: false,
            newProduct: { model: "", isActive: 1 },
            products: [],
            activeProduct: {
                status: false,
                id: 0
            },
        }
    },
    methods: {
        GET_PRODUCT_LIST(search = "", loading) {
            loading(true);
            if (this.setmoutProducts != -1) clearTimeout(this.setmoutProducts);
            this.setmoutProducts = setTimeout(async () => {
                const response = await this.$api.get(
                    `Products?searchKey=${search}&brandId=${this.activeBrand.id}&limit=10&offset=1`
                );
                if (response.result === "OK" && response.message === "OK") {
                    this.products = response.data.items;
                } else this.products = [];
                loading(false);
            }, 500);
        },
        SET_SELECTED_PRODUCT(row) {
            this.activeSerialNumber = { status: false };
            this.activeProduct = { ...row, status: true };
            setTimeout(() => {
                this.GET_SERIAL_NUMBER_LIST("", this.$refs.serialNumbers.toggleLoading);
            }, 250);
        },
        async SEND_NEW_PRODUCT(e, loading) {
            if (this.newProduct.name === "") return;
            loading();
            const response = await this.$api.post("Products", {
                brandId: this.activeBrand.id,
                model: this.newProduct.model,
                isActive: this.newProduct.isActive,
            });
            if (response.result === "OK" && response.message === "OK") {
                this.alert = {
                    status: true,
                    variant: "success",
                    message: "Product saved successfully",
                };
                this.products.unshift({
                    id: response.data,
                    ...this.newProduct,
                });
                this.activeProduct = {
                    id: response.data,
                    ...this.newProduct, status: true
                };

                setTimeout(() => {
                    this.newProductModal = false;
                    this.alert.status = false;
                }, 1500);
            } else {
                this.alert = {
                    status: true,
                    variant: "danger",
                    message: "Something went wrong. Please Try Again",
                };
            }
            loading(false);
        },
    }
}